<template>
    <!-- <div class="pages container" ref="container"></div> -->
    <div class="container">
        <div style="margin-left: 34px; margin-top: 34px">
            <div class="wrap" v-if="qxuid != '5'">
                <div class="title">单个商品订单比例</div>
                <img src="../../assets/shuaxin.png" style="top: 111px; left: 435px; z-index: 66666; width: 170px; position: absolute; width: 20px; height: 20px;cursor: pointer;" @click="refesh" />
                <Tabs type="card" height="246" v-model="oneData" @on-click="tabsClick1" style="top: -19px; left: 356px; z-index: 66666; width: 170px; border: none">
                    <TabPane label="最近七天" name="tab1"></TabPane>
                    <TabPane label="本月" name="tab2"></TabPane>
                </Tabs>
                <!-- <DatePicker
                    type="daterange"
                    placement="bottom-end"
                    :model-value="daterange"
                    @on-change="handleDateChange"
                    placeholder="选择日期"
                    style="width: 200px; position: absolute; top: 103px; left: 260px; z-index: 999999999999999999"
                /> -->
                <DatePicker
                    v-model="daterange"
                    :transfer="true"
                    @on-change="handleDateChange"
                    split-panels
                    confirm
                    :editable="false"
                    type="daterange"
                    style="width: 200px; position: absolute; top: 63px; left: 183px; z-index: 999999999999999991"
                    placeholder="自定义日期"
                ></DatePicker>
                <div class="wrap-item">
                    <div style="position: absolute; position: absolute; top: 222px; left: 214px; font-weight: bold">订单总量:{{ total }}</div>
                    <div id="main" ref="container" style="width: 550px; height: 300px; left: -57px; top: -47px; z-index: 1"></div>
                </div>
            </div>
            <div class="wrap1" v-if="qxuid != '5'">
                <div class="title">订单来源</div>
                <Tabs type="card" height="246" @on-contextmenu="handleContextMenu" @on-click="tabsClick" style="width: 200px; top: -19px; left: 356px; z-index: 2">
                    <TabPane label="年" name="tab3" context-menu></TabPane>
                    <TabPane label="月" name="tab1" context-menu></TabPane>
                    <TabPane label="季" name="tab2" context-menu></TabPane>
                </Tabs>
                <Table :columns="columns" :data="keyArr"></Table>
            </div>
            <div class="wrap2" v-if="qxuid != '5'">
                <div class="title">订单数据</div>
                <div class="wrap-item">
                    <div id="main1" ref="container" style="width: 550px; height: 300px"></div>
                </div>
            </div>
        </div>
        <div style="display: flex; height: 229px; justify-content: center; align-items: center; width: 70%; flex-wrap: wrap; margin-top: 400px" v-if="qxuid != '5'">
            <img src="../../assets/logo.png" style="position: absolute; top: 202px" />
            <div class="item" v-for="item in myArr" :key="item.path" @click="toPage(item.path)">
                <img style="width: 50px; height: 50px; cursor: pointer; border-radius: 8px" src="../../assets/iconcc.jpeg" />
                <div class="item-font">{{ item.title }}</div>
            </div>
            <div class="item" @click="tofunctional">
                <!-- <img style="width: 50px;height: 50px;" src="../../assets/test.png" /> -->
                <Icon type="md-add" style="border-radius: 8px; width: 50px; height: 50px; border: #666666 3px solid; display: flex; justify-content: center; cursor: pointer; align-items: center" />
                <div class="item-font">添加应用</div>
            </div>
        </div>
        <div style="display: flex; height: 229px; justify-content: center; align-items: center; width: 100%; flex-wrap: wrap; margin-top: 400px" v-else>
            <img src="../../assets/logo.png" style="position: absolute; top: 202px" />
            <div class="item" v-for="item in myArr" :key="item.path" @click="toPage(item.path)">
                <img style="width: 50px; height: 50px; cursor: pointer; border-radius: 8px" src="../../assets/iconcc.jpeg" />
                <div class="item-font">{{ item.title }}</div>
            </div>
            <div class="item" @click="tofunctional">
                <!-- <img style="width: 50px;height: 50px;" src="../../assets/test.png" /> -->
                <Icon type="md-add" style="border-radius: 8px; width: 50px; height: 50px; border: #666666 3px solid; display: flex; justify-content: center; cursor: pointer; align-items: center" />
                <div class="item-font">添加应用</div>
            </div>
        </div>
        <div style="margin-right: 34px; margin-top: 34px" v-if="qxuid != '5'">
            <div class="wrap">
                <div class="title">订单比例</div>
                <div class="wrap-item">
                    <div style="display: flex; margin-top: 20px">
                        <div class="orderNum">
                            <div class="numfont">{{ yeartotalamount }}</div>
                            <div>总销售金额</div>
                        </div>
                        <div class="orderNum">
                            <div class="numfont">{{ yeartotalcount }}</div>
                            <div>总成交订单</div>
                        </div>
                        <div class="orderNum">
                            <div class="numfont">{{ yeartotalusernum }}</div>
                            <div>总访问人数</div>
                        </div>
                    </div>
                    <div style="display: flex; margin-top: 20px">
                        <div class="orderNum">
                            <div class="numfont">{{ daytotalamount }}</div>
                            <div>今日销售金额</div>
                        </div>
                        <div class="orderNum">
                            <div class="numfont">{{ daytotlcount }}</div>
                            <div>今日成交订单</div>
                        </div>
                        <div class="orderNum">
                            <div class="numfont">{{ dayusernum }}</div>
                            <div>今日访问人数</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wrap1" v-if="qxuid != '5'">
                <div class="title">最新订单</div>
                <!-- <Select class="width_300" @on-change="select" v-model="province_code" style="margin-bottom: 36px; 
    margin-left: 171px;
    margin-top: -18px;">
                    <Option v-for="info in province_list" :key="info.code" :value="info.code">{{ info.name }}</Option>
                </Select> -->
                <div style="display: flex; height: 31px; margin-left: 160px; margin-bottom: 17px">
                    <city v-model="province_code"></city>
                    <i-button type="primary" style="margin-left: 12px" @click="onsearch">搜索</i-button>
                </div>
                <Table :columns="columns1" :data="newOrderList"></Table>
            </div>
            <div class="wrap2" v-if="qxuid != '5'">
                <div class="title">销售统计</div>
                <div class="wrap-item">
                    <div id="main2" ref="container" style="width: 550px; height: 300px"></div>
                </div>
            </div>
            <!-- <Col class="demo-spin-col" span="8">
                <Spin fix :show="spinShow">
                    <div class="loader">
                        <svg class="circular" viewBox="25 25 50 50">
                            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="5" stroke-miterlimit="10"></circle>
                        </svg>
                    </div>
                </Spin>
            </Col> -->
        </div>
    </div>
</template>
<script>
import pageMixins from '@/lib/pageMixins';
import * as echarts from 'echarts';
import city from '@/components/city';
import theme from '@/view/dataStatistics/comp/echarts/roma';
import { mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import { getFastapps, saveFastapps, getOrder, getAgentStatic, getUser, reqReservationList } from '@/lib/request/auth';
import { pageMenus, filterAsyncRouter } from '@/router/routes';
import { getCityTitles } from '@/lib/city.data';
import OrderList from '../orderManager/orderList.vue';
echarts.registerTheme('roma', theme.roma);

export default {
    mixins: [pageMixins],
    name: 'systemindex',
    components: {
        city,
    },
    data() {
        return {
            spinShow: true,
            oneData: 'tab3',
            province_code: '',
            province_list: [
                {
                    name: '北京市',
                    code: '110000',
                },
                {
                    name: '天津市',
                    code: '120000',
                },
                {
                    name: '河北省',
                    code: '130000',
                },
                {
                    name: '山西省',
                    code: '140000',
                },
                {
                    name: '内蒙古自治区',
                    code: '150000',
                },
                {
                    name: '辽宁省',
                    code: '210000',
                },
                {
                    name: '吉林省',
                    code: '220000',
                },
                {
                    name: '黑龙江省',
                    code: '230000',
                },
                {
                    name: '上海市',
                    code: '310000',
                },
                {
                    name: '江苏省',
                    code: '320000',
                },
                {
                    name: '浙江省',
                    code: '330000',
                },
                {
                    name: '安徽省',
                    code: '340000',
                },
                {
                    name: '福建省',
                    code: '350000',
                },
                {
                    name: '江西省',
                    code: '360000',
                },
                {
                    name: '山东省',
                    code: '370000',
                },
                {
                    name: '河南省',
                    code: '410000',
                },
                {
                    name: '湖北省',
                    code: '420000',
                },
                {
                    name: '湖南省',
                    code: '430000',
                },
                {
                    name: '广东省',
                    code: '440000',
                },
                {
                    name: '广西壮族自治区',
                    code: '450000',
                },
                {
                    name: '海南省',
                    code: '460000',
                },
                {
                    name: '重庆市',
                    code: '500000',
                },
                {
                    name: '四川省',
                    code: '510000',
                },
                {
                    name: '贵州省',
                    code: '520000',
                },
                {
                    name: '云南省',
                    code: '530000',
                },
                {
                    name: '西藏自治区',
                    code: '540000',
                },
                {
                    name: '陕西省',
                    code: '610000',
                },
                {
                    name: '甘肃省',
                    code: '620000',
                },
                {
                    name: '青海省',
                    code: '630000',
                },
                {
                    name: '宁夏回族自治区',
                    code: '640000',
                },
                {
                    name: '新疆维吾尔自治区',
                    code: '650000',
                },
                {
                    name: '台湾省',
                    code: '710000',
                },
                {
                    name: '香港特别行政区',
                    code: '810000',
                },
                {
                    name: '澳门特别行政区',
                    code: '820000',
                },
            ],
            keyArr: [],
            startdate: '2024-1-1',
            enddate: '',
            daterange: '',
            total: 0,
            montDataArr: [],
            myArr: [],
            fastArr: [],
            arr: [],
            menuList: [],
            columns: [
                {
                    title: '排名',
                    key: 'index',
                },
                {
                    title: '城市',
                    key: 'city',
                },
                {
                    title: '订单数',
                    key: 'num',
                },
            ],
            columns1: [
                {
                    title: '服务ID',
                    key: 'sid',
                },
                {
                    title: '预约人',
                    key: 'username',
                },
                {
                    title: '预约时间',
                    key: 'stime',
                },
            ],
            data: [
                {
                    name: 'John Brown',
                    age: 18,
                    address: 'New York No. 1 Lake Park',
                    date: '2016-10-03',
                },
                {
                    name: 'Jim Green',
                    age: 24,
                    address: 'London No. 1 Lake Park',
                    date: '2016-10-01',
                },
                {
                    name: 'Joe Black',
                    age: 30,
                    address: 'Sydney No. 1 Lake Park',
                    date: '2016-10-02',
                },
                {
                    name: 'Jon Snow',
                    age: 26,
                    address: 'Ottawa No. 2 Lake Park',
                    date: '2016-10-04',
                },
            ],
            width: 1950 + 360 * 2,
            contextData: null,
            treeData: {
                name: 'Kinglong琪朗',
                children: [],
            },
            xsArr: [],
            bodyStyle: '',
            marr: [],
            myChart: null,
            lcountArr: [],
            yeartotalcount: 0,
            yeartotalamount: 0,
            yeartotalusernum: 0,
            daytotalamount: 0,
            daytotlcount: 0,
            dayusernum: 0,
            newOrderList: [],
            qxuid: '',
        };
    },
    computed: {
        ...mapGetters(['getRoutes']),
        currentRoute() {
            return this.$route || {};
        },
    },
    created() {
        // this.resize1();
    },
    mounted() {
        var qxuserInfo = localStorage.getItem('SET_USERINFO');
        this.qxuid = JSON.parse(qxuserInfo).groupid;
        console.log(this.qxuid, 'this.groupid');
        var today = new Date();
        // 获取年、月、日
        var year = today.getFullYear(); // 获取当前年份
        var month = today.getMonth() + 1; // 获取当前月份，+1因为getMonth()返回0-11
        var day = today.getDate(); // 获取当前
        // 补零操作，确保月份和日期始终是两位数
        month = (month < 10 ? '0' : '') + month;
        day = (day < 10 ? '0' : '') + day;
        // 构建YYYY-MM-DD格式的日期字符串
        var todayString = year + '-' + month + '-' + day;
        console.log(todayString, 'todayString');
        const previousDays = this.getPreviousDays(7);
        this.menuList = filterAsyncRouter(cloneDeep(pageMenus));
        const currentYear = new Date().getFullYear();
        const firstDayOfYear = new Date(currentYear, 0, 1);
        const date = new Date(firstDayOfYear);
        this.getData3();
        this.getData('zx', 'month', this.formatDate(date));
        this.getData('bt', null, this.formatDate(date));
        this.getData('orderly', null, this.formatDate(date));
        this.getData('yeartj', null, this.formatDate(date));
        this.getData('daytj', null, todayString);
        this.getData2('yeartj', null, this.formatDate(date));
        this.getData2('daytj', null, todayString);
        this.getData1();
        this.getList();
        setTimeout(() => {
            this.$nextTick(() => {
                this.drawzx();
                this.drawzx2();
            });
        }, 2000);
    },
    methods: {
        refesh(){
            var today = new Date();
        // 获取年、月、日
                var year = today.getFullYear(); // 获取当前年份
                var month = today.getMonth() + 1; // 获取当前月份，+1因为getMonth()返回0-11
                var day = today.getDate(); // 获取当前
                // 补零操作，确保月份和日期始终是两位数
                month = (month < 10 ? '0' : '') + month;
                day = (day < 10 ? '0' : '') + day;
                // 构建YYYY-MM-DD格式的日期字符串
                var todayString = year + '-' + month + '-' + day;
                console.log(todayString, 'todayString');
                const previousDays = this.getPreviousDays(7);
                this.menuList = filterAsyncRouter(cloneDeep(pageMenus));
                const currentYear = new Date().getFullYear();
                const firstDayOfYear = new Date(currentYear, 0, 1);
                const date = new Date(firstDayOfYear);
                this.oneData = '';
                this.daterange = '';
            this.getData('bt', null, this.formatDate(date));
        },
        resize1() {
            var scale = 1;
            console.log(window.innerWidth, 'window.innerWidth');

            if (window.innerWidth / window.innerHeight >= app.width / 1080) {
                scale = window.innerHeight / 1080;
            } else {
                scale = window.innerWidth / this.width;
            }
            this.bodyStyle = 'transform:translate3d(-50%,-50%,0) scale(' + scale + ');-webkit-transform:translate3d(-50%,-50%,0) scale(' + scale + ')';
            console.log(this.bodyStyle, 'this.bodyStyle');
            // app.bodyStyle = "transform:translate3d(-50%,-50%,0) scale(0.5);-webkit-transform:translate3d(-50%,-50%,0) scale(0.5)"
        },
        tabsClick1(name) {
            if (name == 'tab1') {
                this.getData('bt', null, this.getPreviousDays(7));
            } else if (name == 'tab2') {
                const date = new Date();
                // 设置当前月的第一天
                date.setDate(1);
                // 输出或返回结果
                let by = date.toISOString().substring(0, 10);
                this.getData('bt', null, by);
            }
        },
        getPreviousDays(n) {
            const today = new Date();
            const previousDay = new Date(today);
            previousDay.setDate(today.getDate() - n);
            const year = previousDay.getFullYear();
            const month = ('0' + (previousDay.getMonth() + 1)).slice(-2); // 月份是从0开始的
            const day = ('0' + previousDay.getDate()).slice(-2);
            return `${year}-${month}-${day}`;
        },
        select() {
            this.params.city = [this.province_code];
            this.getData3();
        },
        onsearch() {
            this.params.city = this.province_code;
            this.getData3();
        },
        getFirstDayOfSeason(date) {
            const month = date.getMonth(); // 获取当前月份（0-11）
            const firstDayOfSeasonMonth = Math.floor(month / 3) * 3; // 获取本季度的第一个月份
            const firstDayOfSeason = new Date(date.getFullYear(), firstDayOfSeasonMonth, 1); // 创建本季度第一天的日期对象
            return firstDayOfSeason; // 返回日期对象
        },
        formatDate(date) {
            const year = date.getFullYear();
            // getMonth() 返回的月份是从 0 开始的，因此需要加 1
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        tabsClick(name) {
            if (name == 'tab1') {
                const date = new Date();
                // 设置当前月的第一天
                date.setDate(1);
                // 输出或返回结果
                let by = date.toISOString().substring(0, 10);
                this.getData('orderly', null, by);
            } else if (name == 'tab2') {
                const today = new Date(); // 假设今天是要查询的日期
                const firstDayOfSeason = this.getFirstDayOfSeason(today);
                const date = new Date(firstDayOfSeason);
                console.log(firstDayOfSeason); // 输出本季度第一天的日期
                this.getData('orderly', null, this.formatDate(date));
            } else {
                const currentYear = new Date().getFullYear();
                const firstDayOfYear = new Date(currentYear, 0, 1);
                console.log(firstDayOfYear, 'firstDayOfYear');
                const date = new Date(firstDayOfYear);
                this.getData('orderly', null, this.formatDate(date));
            }
        },
        handleDateChange(date) {
            this.startdate = date[0];
            this.enddate = date[1];
            this.getData('bt', null, this.startdate, this.enddate);
            setTimeout(() => {
                this.$nextTick(() => {
                    this.draw();
                });
            }, 1000);
        },
        getData3() {
            reqReservationList(this.params).then(res => {
                this.newOrderList = res.data.list.filter((item, index) => index < 4);
            });
        },
        //用户统计
        getData2(name, type, startTime) {
            this.showAjaxLoading();
            getUser({ start: startTime, status: '1,2,3,4', type: type })
                .then(res => {
                    if (name == 'yeartj') {
                        this.yeartotalusernum = res.data.total;
                    } else if (name == 'daytj') {
                        this.dayusernum = res.data.uv_today;
                    }
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        getData1() {
            getAgentStatic({ start: '2024-01-01', status: '0', type: 2 }).then(res => {});
        },
        //折线图饼图表格统计
        getData(name, type, startTime, endTime) {
            this.showAjaxLoading();
            getOrder({ start: startTime, end: endTime, status: '1,2,3,4', type: type })
                .then(res => {
                    // console.log(res.data['2024-01'].count,'count')
                    // this.montDataArr = [res.data['2024-01'].count,res.data['2024-02'].count, res.data['2024-03'].count ,res.data['2024-04'].count, res.data['2024-05'].count, res.data['2024-06'].count ,res.data['2024-07'].count ,res.data['2024-08'].count, res.data['2024-09'].count, res.data['2024-10'].count, res.data['2024-11'].count ,res.data['2024-12'].count]
                    if (name == 'zx') {
                        var arr = [];
                        var marr = [];
                        for (var k in res.data) {
                            //遍历对象，k即为key，obj[k]为当前k对应的值
                            console.log(res.data[k]);
                            arr.push(res.data[k]);
                            marr.push(k);
                        }
                        this.marr = marr;
                        this.montDataArr = arr.map(item => {
                            return item.count;
                        });
                        this.xsArr = arr.map(item => {
                            return item.amount;
                        });
                        setTimeout(() => {
                            this.$nextTick(() => {
                                this.drawzx();
                                this.drawzx2();
                            });
                        }, 2000);
                    } else if (name == 'bt') {
                        var lcountArr = [];
                        this.total = res.data[startTime].count;
                        if (res.data[startTime].data_good.length != 0) {
                            for (var l in res.data[startTime].data_good) {
                                console.log(res.data[startTime].data_good[l].count, 'l.con');
                                var obj = {};
                                obj.name = res.data[startTime].data_good[l].name;
                                obj.count = res.data[startTime].data_good[l].count;
                                lcountArr.push(obj);
                                //遍历对象，k即为key，obj[k]为当前k对应的值
                            }
                            // if(this.lcountArr.length == 1){

                            // }
                            lcountArr.forEach(item => {
                                item.value = item.count;
                                item.name = item.name.slice(0, 6);
                            });
                            if (lcountArr.length > 5) {
                                this.lcountArr = lcountArr.filter((item, index) => index < 5);
                            } else {
                                this.lcountArr = lcountArr.filter((item, index) => index < lcountArr.length);
                            }

                            // this.lcountArr = [
                            //     { value: lcountArr[0].count, name: lcountArr[0].name.slice(0, 6) },
                            //     { value: lcountArr[1].count, name: lcountArr[1].name.slice(0, 6) },
                            //     { value: lcountArr[2].count, name: lcountArr[2].name.slice(0, 6) },
                            //     { value: lcountArr[3].count, name: lcountArr[3].name.slice(0, 6) },
                            //     { value: lcountArr[4].count, name: lcountArr[4].name.slice(0, 6) },
                            // ];
                        } else {
                            this.lcountArr = [];
                        }
                        setTimeout(() => {
                            this.$nextTick(() => {
                                // this.initTreeData();
                                this.draw();
                            });
                        }, 1000);
                        console.log(this.lcountArr, 'this.lcountArr6666666666666');
                    } else if (name == 'yeartj') {
                        this.yeartotalcount = res.data[startTime].count;
                        this.yeartotalamount = res.data[startTime].amount;
                    } else if (name == 'daytj') {
                        this.daytotlcount = res.data[startTime].count;
                        this.daytotalamount = res.data[startTime].amount;
                        console.log('44');
                    } else if (name == 'orderly') {
                        var keyArr = [];
                        var sortArr = [];
                        for (var key in res.data[startTime].data_city) {
                            console.log(key, 'cithkey');
                            console.log(getCityTitles([key.split('_')[0], key.split('_')[1]]), '/*/**/*/*/*');
                            var cityObj = {};
                            cityObj.num = res.data[startTime].data_city[key].count;
                            cityObj.city = getCityTitles([key.split('_')[0], key.split('_')[1]])[1];
                            keyArr.push(cityObj);
                            sortArr.push(res.data[startTime].data_city[key].count);
                            console.log(res.data[startTime].data_city[key], 'res.data.[key]');
                            // arr.push(res.data[k]);
                            // marr.push(k);
                        }
                        var newsortArr = keyArr.sort((a, b) => b.num - a.num);
                        this.keyArr = newsortArr.filter((item, index) => index < 4);
                        this.keyArr.forEach((item, zindex) => {
                            item.index = zindex + 1;
                        });
                    }
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        toPage(path) {
            console.log(this.daterange, 'daterange');
            this.$router.push(path);
        },
        tofunctional() {
            this.$router.push('/functionalConfiguration');
        },
        getList() {
            getFastapps().then(res => {
                if (res.data.fastapps != null) {
                    this.fastArr = res.data.fastapps.split(',');
                    let arr = [];
                    this.fastArr.forEach(item => {
                        this.menuList.forEach(itemName => {
                            if (itemName.name != 'systemIndex') {
                                console.log('666');
                                itemName.children.forEach(item1 => {
                                    if (item == item1.name) {
                                        var obj = {};
                                        obj.title = item1.meta.title;
                                        obj.path = item1.path;
                                        arr.push(obj);
                                    }
                                });
                            }
                        });
                    });
                    this.myArr = arr;
                }
            });
        },
        handleContextMenu(data) {
            this.contextData = data;
        },
        handleContextMenuEdit() {
            this.$Message.info('Click edit of ' + this.contextData.name);
        },
        handleContextMenuDelete() {
            this.$Message.info('Click delete of ' + this.contextData.name);
        },
        initTreeData() {
            this.treeData.children.length = 0;
            let menus = this.getRoutes[0].children || [];
            console.log('menus', menus);
            // let data = [];
            menus.forEach(info => {
                let obj = {};
                obj.name = info.meta.title || '未定义';
                obj.children = [];
                (info.children || []).forEach(subInfo => {
                    obj.children.push({
                        name: subInfo.meta.title || '未定义',
                        path: subInfo.path,
                    });
                });
                if (info.name !== 'systemIndex') {
                    this.treeData.children.push(obj);
                }
            });
            console.log(this.treeData);
        },
        resize() {
            this.myChart && this.myChart.resize();
        },
        //折线图绘制
        drawzx() {
            var chartDom = document.getElementById('main1');
            var myChart = echarts.init(chartDom);
            var option;
            var self = this;
            var arr = JSON.parse(JSON.stringify(this.montDataArr));
            option = {
                title: {
                    text: '',
                },
                tooltip: {
                    trigger: 'axis',
                },
                legend: {
                    data: ['总订单数'],
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                // toolbox: {
                //     feature: {
                //         saveAsImage: {},
                //     },
                // },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: this.marr,
                },
                yAxis: {
                    type: 'value',
                },
                series: [
                    {
                        name: '总订单数',
                        type: 'line',
                        stack: 'Total',
                        data: arr,
                    },
                    // {
                    //     name: '经销商',
                    //     type: 'line',
                    //     stack: 'Total',
                    //     data: [220, 182, 191, 234, 290, 330, 310],
                    // },
                ],
            };
            option && myChart.setOption(option);
        },
        drawzx2() {
            var chartDom = document.getElementById('main2');
            var myChart = echarts.init(chartDom);
            var option;
            option = {
                title: {
                    text: '',
                },
                tooltip: {
                    trigger: 'axis',
                },
                legend: {
                    data: ['总金额'],
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                // toolbox: {
                //     feature: {
                //         saveAsImage: {},
                //     },
                // },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: this.marr,
                },
                yAxis: {
                    type: 'value',
                },
                series: [
                    {
                        name: '总金额',
                        type: 'line',
                        stack: 'Total',
                        data: this.xsArr,
                    },
                ],
            };

            option && myChart.setOption(option);
        },
        //饼图绘制
        draw() {
            this.$nextTick(() => {
                this.myChart = echarts.init(this.$refs.container, 'roma');
                window.onresize = () => {
                    this.resize();
                };
                const resizeObserver = new ResizeObserver(entries => {
                    this.resize();
                });
                resizeObserver.observe(this.$parent.$el);
                var chartDom = document.getElementById('main');
                var myChart = echarts.init(chartDom);
                var option;
                var lcountArr = this.lcountArr;
                console.log(lcountArr, 'lcountArr**************');
                option = {
                    tooltip: {
                        trigger: 'item',
                    },
                    legend: {
                        left: '73.7%',
                        orient: 'vertical', // 设置图例竖向展示
                        x: 'right', // 设置图例位置在右侧
                        y: 'center', // 设置图例垂直居中
                    },
                    series: [
                        {
                            name: '',
                            type: 'pie',
                            radius: ['40%', '70%'],
                            avoidLabelOverlap: false,
                            padAngle: 5,
                            itemStyle: {
                                borderRadius: 10,
                            },
                            label: {
                                show: false,
                                position: 'center',
                            },
                            emphasis: {
                                label: {
                                    show: false,
                                    fontSize: 40,
                                    fontWeight: 'bold',
                                },
                            },
                            labelLine: {
                                show: false,
                            },
                            data: this.lcountArr,
                        },
                    ],
                };
                option && myChart.setOption(option);
                let _this = this;
                this.myChart.on('click', function (params) {
                    console.log(params);
                    if (params.componentType === 'series') {
                        if (!!params.data.path) {
                            _this.goUrl(params.data.path);
                        }
                    }
                });
            });
        },
    },
};
</script>
<style lang="less" scoped>
.container {
    display: flex;
    background-image: url('../../assets/bg.jpg'); /* 替换为您的图片路径 */
    background-size: cover; /* 背景图片覆盖整个页面 */
    background-repeat: no-repeat; /* 背景图片不重复 */
    background-position: center; /* 背景图片居中 */
    width: 100%;
    height: 114vh;
}
.item-font {
    margin-top: 5px;
}
.numfont {
    font-size: 23px;
    font-weight: bold;
}
.orderNum {
    width: 200px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.wrap {
    border-radius: 8px;
    background-color: #fff;
    width: 560px;
    height: 300px;
    padding: 5px;
    margin-top: 15px;
    opacity: 0.9;
}
.wrap1 {
    border-radius: 8px;
    background-color: #fff;
    width: 560px;
    height: 320px;
    padding: 5px;
    margin-top: 15px;
    opacity: 0.9;
}
.wrap2 {
    border-radius: 8px;
    background-color: #fff;
    width: 560px;
    height: 349px;
    padding: 5px;
    margin-top: 15px;
    opacity: 0.9;
}
.item {
    // margin: 0 20px;
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.wrap-item {
    // width:500px;
    // height:300px;
    // background-color: #fff;
    // position:absolute;
    // top:15px;
}
.title {
    margin-left: 10px;
    margin-top: 5px;
    font-weight: bold;
}
.demo-spin-col .circular {
    width: 25px;
    height: 25px;
}
.demo-spin-icon-load {
    animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
    from {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.demo-spin-col {
    height: 100px;
    position: relative;
    border: 1px solid #eee;
}
</style>
